<script src="api/SubscriptionsRepository.js"></script>
<template>
  <v-app id="app">
    <v-navigation-drawer
        v-model="drawer"
        :clipped="$vuetify.breakpoint.lgAndUp"
        app
        v-if="authenticated && hasVerifiedEmail"
    >
      <v-list dense>
        <template v-for="item in items">
          <v-list-item
              :key="item.text"
              link
              @click="$router.push(item.link).catch(err => {})"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item
            v-if="!hasActiveSubscription && subscriptionsAvailable"
            key="create-membership"
            link
            @click="$router.push({name: 'CreateMembership'}).catch(err => {})"
        >
          <v-list-item-action>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Membership
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="hasActiveSubscription"
            key="manage-subscriptions"
            link
            @click="getStripeBillingPortalSessionURL"
        >
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Manage Membership
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            key="manage-subscription"
            link
            @click="getStripeBillingPortalSessionURL"
        >
          <v-list-item-action>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Saved Payment Methods
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-if="hasActiveSubscription"
            key="chat-to-vet"
            link
            @click="$router.push({name: 'ChatToVet'}).catch(err => {})"
        >
          <v-list-item-action>
            <v-icon>mdi-newspaper-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Chat to a vet
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            key="view-invoices"
            link
            @click="$router.push({name: 'ViewInvoices'}).catch(err => {})"
        >
          <v-list-item-action>
            <v-icon>mdi-receipt</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Invoices
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
            key="profile"
            link
            @click="$router.push({name: 'Profile'}).catch(err => {})"
        >
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              My Profile
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        app
        color="primary"
        dark
    >
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="primaryLight"
    ></v-progress-linear>
      <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="authenticated"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <div class="py-2 fill-height">
        <img  class="fill-height"  src="@/assets/images/pb-logo-white.png" @click="$router.push('/').catch(err => {})">
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y
              v-if="authenticated"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              large
              v-bind="attrs"
              v-on="on"
          >
            <v-avatar
                size="32px"
                item
                color="primaryLight"
            >
              <span class="text--primary">{{ user.name | toInitials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              @click="triggerSignOut"
          >
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-container class="mb-15">
        <router-view/>
      </v-container>
      <v-speed-dial
          v-if="!('hideFab' in $route.meta)"
          v-model="fab"
          bottom
          right
          direction="top"
          transition="slide-y-reverse-transition"
          fixed
      >
        <template v-slot:activator>
          <v-btn
              v-model="fab"
              :color="fab ? 'error' : 'accent'"
              fab
          >
            <v-icon v-if="fab">mdi-close</v-icon>
            <v-icon v-else>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-btn
            v-if="authenticated"
            fab
            dark
            small
            color="green"
            @click="$router.push({ name: 'CreateReservation'}).catch(err => {})"
        >
          <v-icon>mdi-calendar</v-icon>
          <div class="fab-text-custom green">New Reservation</div>
        </v-btn>
      </v-speed-dial>
    </v-main>
    <toast></toast>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Toast from './components/ToastComponent.vue'
import ReservationsRepository from "@/api/ReservationsRepository";
import SubscriptionsRepository from "@/api/SubscriptionsRepository";

export default {
  name: 'App',

  components: {
    Toast
  },

  data: () => ({
    fab: false,
    drawer: null,
    items: [
      { icon: 'mdi-home', text: 'Dashboard', link: {name: 'Home'} },
      { icon: 'mdi-newspaper-variant', text: 'Activity Feed', link: {name: 'UpdatesFeed'} },
      { icon: 'mdi-calendar', text: 'Daycare Reservation', link: {name: 'CreateReservation'} },
      { icon: 'mdi-paw', text: 'My Pets', link: {name: 'ViewPets'} },
      { icon: 'mdi-tools', text: 'My Centres', link: {name: 'ViewCentres'} },
      { icon: 'mdi-ticket', text: 'My Packages', link: {name: 'ViewPackages'} },
      { icon: 'mdi-cash-fast', text: 'Purchase Daycare Package', link: {name: 'BuyPackages'} },
    ],
  }),
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      property: 'auth/property',
      hasVerifiedEmail: 'auth/hasVerifiedEmail',
      loading: 'auth/loading',
      hasActiveSubscription: 'auth/hasActiveSubscription',
      subscriptionsAvailable: 'auth/subscriptionsAvailable',
    }),
  },
  methods: {
    ...mapActions({
      me: 'auth/me',
      signOut: 'auth/signOut',
      startLoading: 'auth/startLoading',
      stopLoading: 'auth/stopLoading'
    }),
    triggerSignOut: async function() {
      if(await this.signOut()) {
        await this.$router.push('/login').catch();
      }
    },
    getStripeBillingPortalSessionURL: async function()  {
      this.startLoading();
      const url = await SubscriptionsRepository.getStripeBillingPortalSessionURL();
      this.stopLoading();
      if(url) {
        window.location.href = url;
      }
    }
  },
};
</script>


<style scoped>
.fab-text-custom {
  position: absolute;
  right: 50px;
  background-color: rgba(0,0,0,0.5);
  padding: 10px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
}
</style>